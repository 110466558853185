<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                    <ul id="pills-tab" role="tablist" class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0">
                        <li class="list-inline-item" :class="{'active' : $route.name == 'ClassRegistrationAdmin'}">
                            <router-link :to="{name: 'ClassRegistrationAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'ClassRegistrationAdmin'}">Class Registration</router-link>
                        </li>
                        <li class="list-inline-item" :class="{'active' : $route.name == 'ClassRegistrationProgramSettingAdmin'}">
                            <router-link :to="{name: 'ClassRegistrationProgramSettingAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'ClassRegistrationProgramSettingAdmin'}">Program Settings</router-link>
                        </li>
                        <li class="list-inline-item" :class="{'active' : $route.name == 'ClassRegistrationBatchSettingAdmin'}">
                            <router-link :to="{name: 'ClassRegistrationBatchSettingAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'ClassRegistrationBatchSettingAdmin'}">Batch Settings</router-link>
                        </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-12">
                        <form @submit.prevent="onSubmit()" class="row justify-content-center d-flex">
                            <div class="form-group col-6">
                                <label for="">Program Name</label>
                                <input  type="text" class="form-control" placeholder="Insert program name" v-model="programName" required>
                            </div>
                            <div class="form-group col-6">
                                <label for="">Registration Amount <span class="font-xssss text-warning">(Per Registration)</span></label>
                                <input  type="number" class="form-control" placeholder="Insert your Amount" v-model="amount" required>
                            </div>
                            <div class="form-group col-lg-12 text-right mt-2">
                                <button class="btn btn-current" type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div v-if="!isLoad" class="col-12 py-3">
                        <div class="table-responsive mw-100 pr-2 ml-0">
                            <datatable :class="'table table-bordered'" :columns="columns" :data="programs" :page="1" :perPage="100">
                                <template name="default" slot-scope="{ row, index }">
                                    <tr v-if="row">
                                        <td>{{ index+1 }}</td>
                                        <td>{{ row.name }}</td>
                                        <td>Rp {{ toIDR(row.registration_fee) }}</td>
                                        <td class="text-center">
                                            <div v-if="row.is_active" class="bg-green border-0 text-white fw-600 text-uppercase font-xssss rounded-lg d-inline-block px-2 lh-34 text-center ls-3 w150 mr-2">ACTIVE</div>
                                            <div v-else class="bg-grey border-0 fw-600 text-uppercase font-xssss rounded-lg d-inline-block px-2 lh-34 text-center ls-3 w150 mr-2">INACTIVE</div>
                                        </td>
                                        <td class="text-center">
                                            <button v-if="row.is_active" @click="toggleButton(row.id, false)" class="btn btn-outline-danger mr-2">DEACTIVATE</button>
                                            <button v-else @click="toggleButton(row.id, true)" class="btn btn-outline-success fw-500 mr-2">ACTIVATE</button>
                                            <button @click="deleteButton(row.id)" class="btn btn-outline-danger">DELETE</button>
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td colspan="8">Nothing to see here</td>
                                    </tr>
                                </template>
                            </datatable>
                        </div>
                    </div>
                    <div v-else class="col-12 text-center py-3">
                        <div class="my-3">
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="spinner-grow text-warning mx-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
// import moment from 'moment'
export default {
    name: "Privillage",
    data(){
        return {
            amount: null,
            programName: "",
            programs: [],
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Name', field: 'user.name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Registration Fee', field: 'user.name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Status', field: 'user.email', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Action', field: 'user.username', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false}              
            ],
            isLoad: true
        }
    },
    created(){
        this.retrievePrograms()
    },
    watch:{
        amount: function() {
            this.amount = this.toIDR(this.amount)
        }
    },
    methods:{
        async onSubmit(){
            const payload = {
                registration_fee: Number(this.amount.replaceAll(".","")),
                program_name: this.programName,
                is_active: true,
            }
            console.log(this.amount + this.programName)
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/setting/class-registration/program`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                console.log('success', res.data)
                this.$swal({
                            toast: true,
                            title: 'Program',
                            text: 'Success Add Program',
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        })
                this.programName = ""
                this.amount = null
                this.retrievePrograms()
            }).catch(err => {
                console.log('error', err.response)
            })
        },
        retrievePrograms(){
            this.isLoad = true
            axios.get(`${process.env.VUE_APP_URL_API}/admin/setting/class-registration/program`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.programs = res.data.data
                console.log('success', this.programs)
                this.isLoad = false
            }).catch(err => {
                console.log('error', err.response)
            })
        },
        toIDR(number){
            if(number === undefined){
                return '0'
            }

            if(number === '-' ){
                return '-'
            }

            let regExp = /[a-zA-Z]/g
            if(regExp.test(number.toString())){
                return '0'
            }
            // return number.toString().replace(".","").replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
            const raw = number.toString().replaceAll(".","").replaceAll("IDR","").replaceAll(",","").trim()
            const formatter = new Intl.NumberFormat("ru", {
                style: "currency",
                currency: "IDR"
            })
            const result = formatter.format(raw).toString().slice(0,-7).trim().replace(/\s+/g,".")
            return result
        },
        toggleButton(id, isActive){
            const payload = {
                program_id: id, 
                is_active: isActive
            }
            axios.patch(`${process.env.VUE_APP_URL_API}/admin/setting/class-registration/program`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                console.log('success', res.data)
                this.$swal({
                            toast: true,
                            title: 'Program',
                            text: 'Success Update Program',
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        })
                this.retrievePrograms()
            }).catch(err => {
                console.log('error', err.response)
            })
        },
        deleteButton(id){
            const payload = {
                program_id: id
            }
            axios.patch(`${process.env.VUE_APP_URL_API}/admin/setting/class-registration/delete-program`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                console.log('success', res.data)
                this.$swal({
                            toast: true,
                            title: 'Program',
                            text: 'Success Delete Program',
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true,
                        })
                this.retrievePrograms()
            }).catch(err => {
                console.log('error', err.response)
            })
        }
    }

}
</script>
